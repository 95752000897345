// React imports
import { useState, useEffect, useRef } from 'react';

// App imports
import './styles.scss';

export const Logo = () => {
	const cardRef = useRef<any>(null);
	const [ cursor, setCursor ] = useState({ x: 0, y: 0 });
	const [ gradientCenter, setGradientCenter ] = useState({ cx: "50%", cy: "50%"});

	const handleMouseMove = (e: any) => {
		if (cardRef.current !== null) {
			const rect = cardRef.current.getBoundingClientRect();
			const x = e.clientX - rect.left;
			const y = e.clientY - rect.top;
			setCursor({ x: x, y: y })
		}
	}

	useEffect(() => {
		const svgElement = cardRef.current;
		if (!svgElement) return;

		const cardRect = svgElement.getBoundingClientRect();
		const cxPercentage = ( cursor.x / cardRect.width ) * 100;
		const cyPercentage = ( cursor.y / cardRect.height ) * 100;
		setGradientCenter({
			cx: `${cxPercentage}%`, 
			cy: `${cyPercentage}%`, 
		})
		
	}, [cursor, cardRef])

	useEffect(() => {
	    const svgElement = cardRef.current;
	    if (!svgElement) return;

	    const group = svgElement.querySelector('#logo-group');
	    const paths = Array.from(group.children);

	    let angle = 0;

	    const rotatePaths = () => {
	      angle = (angle + 1) % 360;
	      
	      paths.forEach((path: any, index: any) => {
	        path.setAttribute(
	        	'transform', `
	        		rotate(${angle}, 
	        		${
	        			path.getBBox().x + path.getBBox().width / 2}, 
	        			${path.getBBox().y + path.getBBox().height / 2
	        		})`
	        	);
	      });
	      requestAnimationFrame(rotatePaths);
	    };

	    const animationId = requestAnimationFrame(rotatePaths);

	    return () => {
	      cancelAnimationFrame(animationId);
	    };
	  }, []);

	return (
		<div className="logo-svg-wrapper">
			<svg 
				ref={cardRef} 
				viewBox="0 0 79.9 90.5" 
				className="logo-wrapper" 
				onMouseMove={handleMouseMove}
			>
			<defs>
				<radialGradient
					id="emeralGradient"
					gradientUnits="userSpaceOnUse"
					r="35%"
					cx={gradientCenter.cx}
					cy={gradientCenter.cy}
				>
					<stop stopColor="rgba(234, 240, 115, 1)"/>
					<stop offset={1} stopColor="rgba(172, 208, 56, 0.4)"/>
				</radialGradient>
			</defs>
			<g 
				id="logo-group"
				fill="url(#emeralGradient)"
			>
				<path 
					d="M46.5,45.8c-3.6,2.2-6,4.8-7.8,4.5c-1.9-0.4-4.7-3.7-4.5-5.5c0.1-1.8,3.4-4.6,5.4-4.7
					C41.4,40.1,43.3,43,46.5,45.8L46.5,45.8z"/>
				<path d="M33.3,38.5c-2.9,1.9-4.9,4.3-6.3,3.9c-1.8-0.5-3-3.1-4.4-4.8c1.6-1.4,3.2-3.8,4.8-3.8
					C29.1,33.9,30.7,36.3,33.3,38.5z"/>
				<path d="M51.1,31.8c2.1,2.9,4.5,4.8,4.2,6.2c-0.4,1.8-2.9,3.2-4.5,4.7c-1.4-1.6-3.8-3.1-4-4.7
					C46.7,36.4,49,34.6,51.1,31.8L51.1,31.8z"/>
				<path d="M45.5,31c-3,2.1-4.8,4.4-6.5,4.4c-1.5,0-4.3-2.9-4.1-4.1c0.3-1.8,2.6-4.1,4.3-4.4C40.6,26.6,42.5,28.9,45.5,31
					z"/>
				<path d="M27,45.9c2.1,2.8,4.4,4.5,4.3,6.1s-2.5,3.1-3.9,4.7c-1.6-1.5-4.2-2.8-4.5-4.6C22.6,50.7,25,48.7,27,45.9
					L27,45.9z"/>
				<path d="M57.3,52c-2.7,2.1-4.3,4.4-5.9,4.4c-1.7-0.1-3.2-2.4-4.8-3.8c1.5-1.7,2.7-4.3,4.5-4.8
					C52.4,47.6,54.5,50,57.3,52L57.3,52z"/>
				<path d="M45.4,59.5c-2.8,1.9-4.6,4.1-6.2,3.9c-1.6-0.2-3-2.6-4.5-4.1c1.6-1.6,3-4.1,4.8-4.5
					C40.8,54.7,42.6,57.3,45.4,59.5L45.4,59.5z"/>
				<path d="M44.6,16.9c-2.5,1.9-4.1,4-5.2,3.8c-1.5-0.4-3.5-2.3-3.8-3.8c-0.2-1,2.1-3.4,3.5-3.5
					C40.4,13.2,42,15.1,44.6,16.9L44.6,16.9z"/>
				<path d="M20.7,59.4c-2.6,1.8-4.1,3.7-5.4,3.6c-1.4-0.2-3.7-2.5-3.5-3.5c0.3-1.5,2.2-3.5,3.7-3.8
					C16.5,55.4,18.1,57.5,20.7,59.4L20.7,59.4z"/>
				<path d="M68.6,59.1c-2.5,1.9-4,3.9-5.3,3.8c-1.4-0.1-3.9-2.3-3.7-3.2c0.3-1.6,2-3.6,3.6-4
					C64.2,55.3,65.9,57.3,68.6,59.1L68.6,59.1z"/>
				<path d="M26.9,71.9c-1.6-2.7-3.5-4.4-3.3-5.6c0.4-1.5,2.5-2.6,3.9-3.9c1.1,1.4,3,2.8,3.1,4.2
					C30.6,68,28.7,69.4,26.9,71.9z"/>
				<path d="M51.2,72c-1.8-2.6-3.7-4-3.7-5.4c0.1-1.4,2-2.7,3.2-4.1c1.4,1.3,3.5,2.4,3.8,4C54.8,67.6,52.9,69.3,51.2,72
					L51.2,72z"/>
				<path d="M39.3,79c-1.8-2.6-3.7-4.1-3.7-5.5c0.1-1.4,2-2.8,3.2-4.2c1.4,1.3,3.5,2.4,3.8,4C42.9,74.6,41,76.3,39.3,79
					L39.3,79z"/>
				<path d="M68.6,45c-2.6,1.9-4.2,4-5.3,3.8c-1.5-0.3-2.7-2.4-4-3.7c1.2-1.2,2.4-3.3,3.8-3.5C64.4,41.4,65.9,43.2,68.6,45
					z"/>
				<path d="M20.8,45.1c-2.6,1.9-4.3,3.9-5.4,3.7c-1.5-0.4-2.7-2.4-4-3.8c1.3-1.2,2.4-3.2,3.8-3.4
					C16.5,41.5,18.1,43.3,20.8,45.1L20.8,45.1z"/>
				<path d="M32.7,24.2C30,26,28.3,28,27.2,27.7c-1.5-0.4-3.3-2.5-3.5-4c-0.1-0.9,2.3-3.1,3.7-3.2
					C28.7,20.4,30.1,22.3,32.7,24.2z"/>
				<path d="M51.2,18.4c1.7,2.7,3.6,4.4,3.3,5.6c-0.4,1.5-2.5,2.6-3.9,3.9c-1.1-1.4-3.1-2.7-3.1-4.1
					C47.5,22.5,49.4,21,51.2,18.4z"/>
				<path d="M15,36.9c-1.8-2.8-3.5-4.4-3.3-5.7c0.2-1.4,2.2-2.6,3.4-3.9c1.3,1.4,3.3,2.6,3.6,4.1
					C18.9,32.5,16.9,34.1,15,36.9L15,36.9z"/>
				<path d="M68.6,30.8c-2.6,2-4.1,4.1-5.2,3.9c-1.5-0.3-2.8-2.3-4.1-3.6c1.2-1.3,2.3-3.3,3.7-3.5
					C64.3,27.3,65.9,29.1,68.6,30.8z"/>
				<path d="M79.3,38c-1.8,1.5-2.9,3.2-4,3.2s-3.3-1.9-3.2-2.6c0.3-1.4,1.6-3.1,2.9-3.5C75.8,34.8,77.4,36.6,79.3,38
					L79.3,38z"/>
				<path d="M7.4,38.2c-1.8,1.5-3.3,3.3-4.1,3.1C2,40.8,1.1,39.1,0,37.9c1.1-1,2.2-2.7,3.3-2.8C4.4,35.1,5.6,36.8,7.4,38.2
					L7.4,38.2z"/>
				<path d="M3,28.5c-1.4-2-3.1-3.5-2.8-4.4c0.4-1.3,2.2-2.2,3.4-3.3c0.9,1.2,2.5,2.3,2.5,3.5C6.1,25.4,4.5,26.6,3,28.5
					L3,28.5z"/>
				<path d="M74.8,19.7c1.5,1.8,3.3,3.1,3.1,4.1c-0.2,1.3-2,2.4-3,3.5c-1-1.1-2.8-2.1-2.9-3.2C72,23,73.5,21.7,74.8,19.7z"
					/>
				<path d="M3.1,56.6c-1.4-1.9-3.1-3.3-2.9-4.3C0.5,51,2.3,50.1,3.5,49c0.9,1.1,2.6,2.2,2.6,3.4
					C6.1,53.6,4.5,54.8,3.1,56.6L3.1,56.6z"/>
				<path d="M78.5,53.2c-1.3,0.8-2.9,2.5-3.7,2.1c-1.3-0.5-1.9-2.4-2.9-3.7c1.2-0.8,2.4-2.2,3.6-2.3c1.4-0.1,2.9,1,4.4,1.6
					C79.5,51.7,79,52.4,78.5,53.2z"/>
				<path d="M19.4,17c-1.8,1.4-3,3-4.1,3c-1.2,0-2.3-1.7-3.4-2.6c1.1-1.2,1.9-3,3.2-3.4C16,13.7,17.5,15.5,19.4,17z"/>
				<path d="M63.3,12.6c1.3,2,3,3.5,2.7,4.4c-0.3,1.2-2.2,2.1-3.4,3.1c-0.9-1.1-2.5-2.3-2.5-3.4S61.8,14.4,63.3,12.6
					L63.3,12.6z"/>
				<path d="M55.4,9.8c-1.9,1.5-3.1,3.2-4.1,3.1c-1.2-0.1-3.1-1.7-3.1-2.7c0-1.1,1.6-3,2.8-3.2C52,6.8,53.3,8.4,55.4,9.8
					L55.4,9.8z"/>
				<path d="M43.4,3.4c-2.1,1.2-3.5,2.7-4.6,2.5c-1.1-0.2-2-2-2.9-3.2C37,1.7,38.2,0,39.3,0C40.5,0,41.6,1.8,43.4,3.4z"/>
				<path d="M7.5,66.4c-2,1.4-3.3,3.1-4.4,3c-1.2-0.2-2.9-2-2.9-3.1c0-1,1.9-2.7,3-2.8C4.3,63.3,5.6,65,7.5,66.4z"/>
				<path d="M74.7,70.8c-1.3-2.1-2.8-3.5-2.6-4.6c0.2-1.1,2.1-2.7,3.2-2.7c1,0.1,2.7,2,2.7,3.1
					C78,67.7,76.3,68.9,74.7,70.8L74.7,70.8z"/>
				<path d="M27.2,5.5c1.4,2.1,2.9,3.4,2.8,4.5s-1.9,2.1-3,3.1c-1-1.1-2.8-2.1-2.8-3.2C24.1,8.8,25.7,7.5,27.2,5.5
					L27.2,5.5z"/>
				<path d="M14.9,77.8c-1.3-2.1-2.8-3.5-2.7-4.6c0.2-1.1,2.1-2.8,3.2-2.7c1,0,2.7,2,2.7,3.1
					C18.1,74.7,16.4,75.9,14.9,77.8L14.9,77.8z"/>
				<path d="M62.8,68.9c1.6,1.9,3.3,3.1,3.2,4.2c-0.1,1.2-1.8,2.3-2.8,3.4c-1.1-1-2.9-1.9-3.1-3
					C59.9,72.4,61.5,71,62.8,68.9z"/>
				<path d="M27.2,84.8c-1.5-2-3.1-3.2-3-4.3c0.1-1.1,1.8-2.9,2.9-3c1,0,2.8,1.7,3,2.9C30.1,81.4,28.6,82.7,27.2,84.8z"/>
				<path d="M51.1,76c1.4,2,3,3.3,2.9,4.4c-0.1,1.2-1.9,2.1-3,3.2c-1-1.1-2.8-2.1-2.9-3.2C48,79.2,49.6,78,51.1,76L51.1,76
					z"/>
				<path d="M39.1,83c1.4,2,3.1,3.3,3,4.3c-0.1,1.2-1.9,2.1-2.9,3.2c-1-1.1-2.8-2-3-3.2C36,86.3,37.6,85,39.1,83L39.1,83z"
					/>
			</g>
			</svg>
		</div>
	)
}

Logo.displayName="Logo";